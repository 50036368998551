import React from "react"
import Container from "react-bootstrap/Container"
import { useTranslation } from "react-i18next"
import Img from "gatsby-image/withIEPolyfill"

import { itunesLink, googlePlayLink } from "../../constants/links"

import itunesBadge from "../../images/itunes-badge.svg"
import androidBadge from "../../images/android-badge.svg"

import styles from "./hero.module.css"

export default function Hero({ title, text, phone }) {
  const { t } = useTranslation()

  return (
    <div className={styles.hero}>
      <Container fluid="md">
        <div className="row">
          <div className={`col-md-6 ${styles.heroContent}`}>
            <h1 className={styles.heroTitle}>{title}</h1>
            <p className={styles.heroText}>{text}</p>
            <div className={styles.heroDownload}>
              <a
                href={itunesLink}
                className={styles.heroDownloadButton}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt={`${t("image.alt")} Iphone app on Itunes`}
                  src={itunesBadge}
                />
              </a>
              <a
                href={googlePlayLink}
                className={styles.heroDownloadButton}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt={`${t("image.alt")} Android app on Google Play`}
                  src={androidBadge}
                />
              </a>
            </div>
          </div>
          <div className="col-md-6">
            <Img
              fluid={phone}
              objectFit="cover"
              objectPosition="50% 50%"
              alt={`${t("image.alt")} ${title}`}
              className={styles.heroPhone}
            />
          </div>
        </div>
      </Container>
    </div>
  )
}
