/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect } from "react"
import { useTranslation, Trans } from "react-i18next"

import { graphql } from "gatsby"
import Container from "react-bootstrap/Container"

import i18next from "../i18n/config"

/* Images */
import NavBarLogo from "../images/logo.svg"

/* Components */
import Wrapper from "../components/wrapper/wrapper"
import SEO from "../components/seo/seo"
import NavBar from "../components/navbar/navbar"
import Main from "../components/main/main"
import Hero from "../components/hero/hero"
import Features from "../components/features/features"
import FeaturesItem from "../components/features-item/features-item"
import Hr from "../components/hr/hr"
import Faqs from "../components/faqs/faqs"
import FaqsItem from "../components/faqs-item/faqs-item"
import Footer from "../components/footer/footer"

export default function Home({ location, data }) {
  const { t } = useTranslation()

  const HeroTitle = t("pages.home.hero.title")
  const HeroText = t("pages.home.hero.text")

  const FeaturesHome = t("pages.home.features")

  const RenderFeatures = []

  for (const [index, feature] of FeaturesHome.entries()) {
    const image = data[feature.image]

    RenderFeatures.push(
      <FeaturesItem
        key={index}
        index={index}
        image={image.childImageSharp.fluid}
        title={feature.title}
        text={feature.text}
      />
    )
  }

  const FaqsTitle = t("pages.home.faqs.title")

  const FaqsItems = t("pages.home.faqs.items")

  const RenderFaqsItems = []

  for (const [index, faq] of FaqsItems.entries()) {
    const i18nKey = `pages.home.faqs.items.${index}.answer`
    RenderFaqsItems.push(
      <FaqsItem key={index} index={index} title={faq.title}>
        <Trans i18n={i18next} i18nKey={i18nKey}>
          {faq.answer}
          <a>.</a>
        </Trans>
      </FaqsItem>
    )
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Wrapper>
      <SEO />
      <NavBar classVariant="navBarDefault" logo={NavBarLogo} />
      <Main>
        <Hero
          title={HeroTitle}
          text={HeroText}
          phone={data.phone.childImageSharp.fluid}
        />
        <Container>
          <Features>{RenderFeatures}</Features>
          <Hr />
          <Faqs title={FaqsTitle}>{RenderFaqsItems}</Faqs>
        </Container>
      </Main>
      <Footer />
    </Wrapper>
  )
}

export const query = graphql`
  query IndexQuery {
    phone: file(relativePath: { eq: "home-screenshot.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    slide1: file(relativePath: { eq: "slide-1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    slide2: file(relativePath: { eq: "slide-2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    slide3: file(relativePath: { eq: "slide-3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
